<template>
  <div class="Part">
<!--    双击弹出开始-->
    <el-dialog :visible.sync="detailsWng" :close-on-click-modal="false" width="800px" top="10vh" @close="cleanDialog">
<!--      行位预警详情-->
      <div v-if="warningType == 0">
        <div>
          <!--        <img :src="row.snapPath" alt="" style="width: 100%;height: 100%">-->
          <video :src="row.videoPath" :poster="row.snapPath" type="video/mp4" ref="video" autoplay controls style="width:760px;height: 450px;object-fit:fill;"></video>
        </div>
        <el-row>
          <el-col class="location" :span="8">
            <p>{{row.deviceName}}</p>
            <p>{{row.startTime}}</p>
          </el-col>
          <el-col :span="16">
            <p class="types-2">{{row.processingResult}}</p>
            <p class="types">{{row.types}}</p>
          </el-col>
        </el-row>
        <el-divider></el-divider>
        <el-button @click="detailsWng = false" class="returnBtn" size="mini">返 回</el-button>
        <el-button v-show="row.processingResult == '待处理'" type="primary" @click="disposeBtn" class="returnBtn" size="mini">处 理</el-button>
        <el-button v-show="row.processingResult == '待处理'" type="danger" @click="miscalculationBtn" class="returnBtn" size="mini">误 判</el-button>
      </div>
      <div v-else>
        <el-form>
          <el-row>
            <el-col :span="8">
              <el-form-item label="设备类型:" label-width="75px">{{row.dftName}}</el-form-item>
              <el-form-item label="预警时间:" label-width="75px">{{row.jdwWarningTime}}</el-form-item>
              <el-form-item label="人数:" label-width="75px">{{row.teacherAndStudentCount}}</el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="设备名称:" label-width="75px">{{row.devNickname}}</el-form-item>
              <el-form-item label="日报警数:" label-width="75px">{{row.count}}</el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="设备位置:" label-width="75px">{{row.hsAddBuilding}}</el-form-item>
              <el-form-item label="处理类型:" label-width="75px">{{row.jdwHandleStatus}}</el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <el-divider></el-divider>
        <el-button @click="detailsWng = false" class="returnBtn" size="mini">返 回</el-button>
        <el-button v-show="row.jdwHandleStatus == '未处理'" type="primary" @click="equipmentDisposeBtn" class="returnBtn" size="mini">处 理</el-button>
      </div>
    </el-dialog>
<!--    双击弹出结束-->
    <el-row style="padding: 5px 5px 5px 0;" ref="wngRow">
      <el-form>
        <el-col :span="4">
          <el-form-item label="预警类型:" label-width="75px">
            <el-select v-model="warningType" placeholder="请选择" size="mini" @change="warningTypeChange">
              <el-option label="行为预警" value="0"></el-option>
              <el-option label="设备预警" value="1"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="4" v-if="warningType == 0">
          <el-form-item label="设备名称:" label-width="75px">
            <el-input v-model="deviceName" @change="locationChange" size="mini" clearable></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <el-form-item label="处理类型:" label-width="75px">
            <el-select v-model="processingResult" @change="processingChange" placeholder="请选择" size="mini">
              <el-option label="未处理" value="0"></el-option>
              <el-option label="已处理" value="1"></el-option>
              <el-option label="全部" value="2"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="4" v-if="warningType == 1">
          <el-form-item label="设备类型:" label-width="75px">
            <el-select v-model="deviceType" placeholder="请选择" @change="deviceTypeChange" size="mini" clearable>
              <el-option label="门锁" value="3"></el-option>
              <el-option label="烟雾报警器" value="9"></el-option>
              <el-option label="红外报警器" value="10"></el-option>
              <el-option label="水浸报警器" value="11"></el-option>
              <el-option label="燃气报警器" value="12"></el-option>
              <el-option label="门磁报警器" value="13"></el-option>
              <el-option label="水表" value="14"></el-option>
              <el-option label="电表" value="15"></el-option>
              <el-option label="电箱" value="16"></el-option>
              <el-option label="SOS紧急呼叫器" value="21"></el-option>
              <el-option label="门禁锁" value="24"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="4" v-if="warningType == 0">
          <el-form-item label="事件类型:" label-width="75px">
            <el-select v-model="types" placeholder="请选择" clearable size="mini" @change="typesChange">
              <el-option label="倒地事件" value="倒地事件"></el-option>
              <el-option label="求救事件" value="求救事件"></el-option>
              <el-option label="闯入事件" value="闯入事件"></el-option>
              <el-option label="打架事件" value="打架事件"></el-option>
              <el-option label="聚众事件" value="聚众事件"></el-option>
              <el-option label="攀爬事件" value="攀爬事件"></el-option>
              <el-option label="离床事件" value="离床事件"></el-option>
              <el-option label="攀高事件" value="攀高事件"></el-option>
              <el-option label="入厕尾随" value="入厕尾随"></el-option>
              <el-option label="入厕超时" value="入厕超时"></el-option>
              <el-option label="徘徊检测" value="徘徊检测"></el-option>
              <el-option label="滞留检测" value="滞留检测"></el-option>
              <el-option label="睡岗检测" value="睡岗检测"></el-option>
              <el-option label="离岗检测" value="离岗检测"></el-option>
              <el-option label="缺岗事件" value="缺岗事件"></el-option>
              <el-option label="警戒检测" value="警戒检测"></el-option>
              <el-option label="逆行检测" value="逆行检测"></el-option>
              <el-option label="独处检测" value="独处检测"></el-option>
              <el-option label="单人提审" value="单人提审"></el-option>
              <el-option label="离开事件" value="离开事件"></el-option>
              <el-option label="消防事件" value="消防事件"></el-option>
              <el-option label="烟雾检测" value="烟雾检测"></el-option>
              <el-option label="颜色检测" value="颜色检测"></el-option>
              <el-option label="误判" value="误判"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="7">
          <el-form-item label="预警时间:" label-width="75px">
            <el-date-picker
              v-model="startTime"
              @change="timeChange"
              unlink-panels
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              format="yyyy 年 MM 月 dd 日"
              value-format="yyyy-MM-dd"
              style="width: 100%"
              size="mini"></el-date-picker>
          </el-form-item>
        </el-col>
      </el-form>
    </el-row>
    <!-- 内容表格 -->
    <div>
      <el-table
        v-if="warningType == '0'"
        :data="dataList"
        highlight-current-row
        @cell-click="currentChangeData"
        @row-dblclick="dbClickData"
        :height='tableHeight'
        style="width:100%;">
        <el-table-column prop="deviceName" label="事件位置" align="center" min-width="130px"></el-table-column>
        <el-table-column prop="startTime" label="预警时间" align="center" min-width="150px"></el-table-column>
        <el-table-column prop="types" label="预警类型" align="center" min-width="100px"></el-table-column>
        <el-table-column label="预警照片" align="center" min-width="100px">
          <template slot-scope="scope" v-if="warningType == '0'">
            <img :src="scope.row.snapPath" style="width: 60px;height: 50px;"/>
          </template>
        </el-table-column>
        <el-table-column prop="processingResult" label="处理类型" align="center" min-width="100px"></el-table-column>
      </el-table>
      <el-table
        v-else
        :data="dataList"
        highlight-current-row
        @cell-click="currentChangeData"
        @row-dblclick="dbClickData"
        :height='tableHeight'
        style="width:100%;">
        <el-table-column prop="dftName" label="设备类型" align="center" min-width="130px"></el-table-column>
        <el-table-column prop="devNickname" label="设备名称" align="center" min-width="130px"></el-table-column>
        <el-table-column prop="hsAddCommunity" label="设备位置" align="center" min-width="150px"></el-table-column>
        <el-table-column prop="jdwWarningTime" label="预警时间" align="center" min-width="100px"></el-table-column>
        <el-table-column prop="count" label="日预警数" align="center" min-width="100px"></el-table-column>
        <el-table-column prop="teacherAndStudentCount" label="人数" align="center" min-width="100px"></el-table-column>
        <el-table-column prop="jdwHandleStatus" label="处理类型" align="center" min-width="100px"></el-table-column>
      </el-table>
      <el-pagination
        background layout="total, sizes, prev, pager, next, jumper"
        align="center"
        :page-count="pages"
        :page-size="size"
        :total="total"
        :current-page="current"
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
        :page-sizes="[5,10,20,40]"
      ></el-pagination>
    </div>
  </div>
</template>
<script>
  export default {
    name:'equipmentWng',
    data(){
      return{
        tableHeight: 0,
        dataList: [],
        detailsWng: false,
        deviceName: '',//事件类型
        types: '',//预警类型
        processingResult: '2',//处理类型
        startTime: [],//预警时间
        deviceType: '',
        row: '',
        processingContent: '',
        warningType: '0',
        typeState: '',
        total: 0,         //总条数
        size: 10,        //每页的条数
        pages: 0,      //每页数据
        current: 0,    //初始页
      }
    },
    created() {
      // this.earlyWarningList()
      // this.pagingDeviceWarning()
      this.warningTypeChange()
    },
    updated(){
      this.tableHeight = window.innerHeight - this.$refs.wngRow.$el.offsetHeight - 130
    },
    methods: {
      warningTypeChange(){
        if(this.warningType == '0'){
          this.earlyWarningList()
        }else {
          this.pagingDeviceWarning()
        }
      },
      earlyWarningList(){//行为预警分页查询
        this.dataList = []
        if(this.startTime == null){
          this.startTime = []
        }
        this.$axios({
          method: 'get',
          url: `/common2/earlyWarning/pagingWarningNotice`,
          params: {
            deviceName: this.deviceName,
            eventType: this.types,
            processingResult: this.processingResult,
            startTime: this.startTime[0],
            endTime: this.startTime[1],
            cursor: this.current,
            limit: this.size
          }
        }).then(res => {
          if(res.data.result.records){
            this.dataList = res.data.result.records
          }else{
            this.dataList = []
          }
          this.total=res.data.result.total;
          this.size=res.data.result.size;
          this.pages=res.data.result.pages;
          this.current=res.data.result.current;
          for(let i of this.dataList){
            i.types = i.types[0]
            i.processingResult = i.processingResult == 0 ? '待处理' : '已处理'
          }
        }).catch(err => {
          this.isError(err,this)
        })
      },
      pagingDeviceWarning(){//设备预警分页查询
        this.dataList = []
        // this.deviceType = Number(this.deviceType)
        this.$axios({
          method: 'get',
          url: `/common2/earlyWarning/pagingDeviceWarning`,
          params: {
            cursor: this.current,
            limit: this.size,
            processingResult: this.processingResult,
            startTime: this.startTime[0],
            endTime: this.startTime[1],
            dftId: this.deviceType
          }
        }).then(res => {
          if(res.data.result.records){
            this.dataList = res.data.result.records
            for(let i of res.data.result.records){
              let a = i.hsAddCommunity ? `${i.hsAddCommunity} ` : ''
              let b = i.hsRoomType? `${i.hsRoomType} ` : ''
              let c = i.hsAddDoorplateno ? `${i.hsAddDoorplateno}` : ''
              i.hsAddCommunity = a+b+c
            }
          }else{
            this.dataList = []
          }
          this.total=res.data.result.total;
          this.size=res.data.result.size;
          this.pages=res.data.result.pages;
          this.current=res.data.result.current;
        }).catch(err => {
          this.dataList = []
          this.isError(err,this)
        })
      },
      locationChange(){
        this.earlyWarningList()
      },
      processingChange(){//处理类型筛选框
        if(this.warningType == '0'){
          this.earlyWarningList()
        }else {
          this.pagingDeviceWarning()
        }
      },
      typesChange(){
        this.earlyWarningList()
      },
      deviceTypeChange(){//设备类型筛选框
        this.pagingDeviceWarning()
      },
      timeChange(){
        if(this.warningType == '0'){
          this.earlyWarningList()
        }else{
          this.pagingDeviceWarning()
        }
      },
      handleCurrentChange(cursor) {
        this.current = cursor
        if(this.warningType == '0'){
          this.earlyWarningList()
        }else{
          this.pagingDeviceWarning()
        }
      },
      handleSizeChange(limit){
        this.size = limit
        if(this.warningType == '0'){
          this.earlyWarningList()
        }else{
          this.pagingDeviceWarning()
        }
      },
      currentChangeData(row){//当表改变时
        this.row = row;
      },
      dbClickData(){//表格双击
        this.detailsWng = true
        console.log(this.row);
      },
      disposeBtn() {
        this.$prompt('请输入处理内容', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
        }).then(({ value }) => {
          this.processingContent = value
          this.typeState = 0
          this.manage()
        })
      },
      miscalculationBtn(){
        this.$confirm('是否将此事件处理为误判?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.typeState = 1
          this.manage()
        }).catch(() => {

        });
      },
      manage(){//行位预警处理
        this.$axios({
          method: 'put',
          url: `/common2/earlyWarning`,
          data: {
            jbmfContent: this.processingContent,
            jbmfEventId:  this.row.eventId,
            type: this.typeState
          }
        }).then(res => {
          this.$message.success('已处理')
          this.earlyWarningList()
          this.detailsWng = false
        }).catch(err => {
          this.isError(err,this)
        })
      },
      equipmentDisposeBtn(){//设备预警处理
        this.$axios({
          method: 'put',
          url: `/common2/earlyWarning/equipmentConditionHandle/${this.row.jdwId}`
        }).then(res => {
          this.$message.success('已处理')
          this.detailsWng = false
          this.pagingDeviceWarning()
        }).catch(err => {
          this.isError(err,this)
        })
      },
      cleanDialog(){//清空弹框model
        this.processingContent = ''
      }
    }
  }
</script>
<style lang="scss" scoped>
  .types-2{
    display: inline-block;
    height: 22px;
    width: 70px;
    background-color: #24a48d;
    text-align: center;
    line-height: 22px;
    border-radius: 25px;
    font-size: 12px;
    color: #ffffff;
    // float: right;
  }
  .types{
    display: inline-block;
    height: 22px;
    width: 70px;
    background-color: #2d63ca;
    text-align: center;
    line-height: 22px;
    border-radius: 25px;
    font-size: 12px;
    color: #ffffff;
    // float: right;
    margin-right: 7px;
  }
  .location{
    p:nth-of-type(1){
      font-size: 16px;
      margin: 10px 0 0;
    }
    p:nth-of-type(2){
      margin-top: 5px;
      margin-bottom: 0;
    }
  }
  .el-divider{
    margin-bottom: 5px;
  }
  .returnBtn{
    float: right;
    font-size: 13px!important;
  }
</style>
